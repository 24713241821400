import { css } from "@emotion/react";
import PostsModuleFilterForm from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterForm";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PostsModuleHeader from "@municipio/gatsby-theme-basic/src/components/PostsModuleHeader";
import { Link } from "@whitespace/components";
import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import { getMainArchivePagePathFromPageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/contentType";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../../Grid";

import * as defaultStyles from "./PostsModuleGridLayout.module.css";

PostsModuleGridLayout.propTypes = {
  className: PropTypes.string,
  itemComponent: PropTypes.elementType.isRequired,
  module: PropTypes.shape({
    modPostsDataDisplay: PropTypes.shape({
      postsFields: PropTypes.arrayOf(PropTypes.string),
      theme: PropTypes.string,
    }),
    modPostsDataSource: {
      archiveLink: PropTypes.bool,
      customArchiveLink: PropTypes.bool,
      postsDataPostType: PropTypes.shape({
        hasArchive: PropTypes.bool,
        uri: PropTypes.string,
        labels: PropTypes.shape({
          allItems: PropTypes.string,
          menuName: PropTypes.string,
        }),
      }),
    },
  }).isRequired,
  normalizedItems: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
};

export default function PostsModuleGridLayout({
  styles = defaultStyles,
  className,
  itemComponent: Item,
  title,
  module,
  normalizedItems,
  ...restProps
}) {
  const { schema } = useSearch();
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  const {
    modPostsDataDisplay: { theme },
  } = module;

  const archiveLinkLabel = module.modPostsDataSource?.customArchiveLink?.url
    ? module.modPostsDataSource?.customArchiveLink?.title
    : module.modPostsDataSource?.postsDataPostType?.labels?.allItems;

  const archiveLinkUri = module.modPostsDataSource?.customArchiveLink?.url
    ? module.modPostsDataSource?.customArchiveLink?.url
    : module.modPostsDataSource?.postsDataPostType &&
      getMainArchivePagePathFromPageContext({
        contentType: module.modPostsDataSource?.postsDataPostType,
      });
  const showArchiveLink =
    module.modPostsDataSource?.archiveLink &&
    (module.modPostsDataSource?.customArchiveLink?.url ||
      module.modPostsDataSource?.postsDataPostType?.hasArchive) &&
    archiveLinkUri &&
    archiveLinkLabel;

  console.log(normalizedItems);

  const filteredItems = normalizedItems.filter(
    (item) => {
      if (!item) {
        return false;
      }

      if (!item.contentType || !item.url) {
        return true;
      }

      if (currentLanguage === "sv") {
        return !item.url.includes("/en/") && !item.url.includes("/de/");
      } else if (currentLanguage === "en") {
        return item.url?.includes("/en/");
      } else if (currentLanguage === "de") {
        return item.url?.includes("/de/");
      }
    }
  );

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, theme, className)}
      css={css({
        "--card-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : 'var(--card-default-background)',
        "--card-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : 'var(--card-default-text-color)',
        "--card-hover-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : 'var(--card-default-text-color)',
        "--card-meta-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : 'var(--card-default-text-color)',
        "--module-wrapper-title-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : 'var(--card-default-background)',
      })}
      components={{
        ModuleWrapperHeader: PostsModuleHeader,
      }}
    >
      <PostsModuleFilterForm className={clsx(styles.filterForm)} />
      <Grid className={clsx(styles.list)} autoFit={!schema}>
        {filteredItems.map((item, index) => {
          return <Item key={index} className={clsx(styles.item)} item={item} />;
        })}
      </Grid>
      {!!showArchiveLink && (
        <div className={styles.moduleCta}>
          <Link className={styles.archiveLink} to={archiveLinkUri}>
            {archiveLinkLabel}
          </Link>
        </div>
      )}
    </ModuleWrapper>
  );
}
