import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as defaultStyles from "./ArticleFeaturedImage.module.css";

ArticleFeaturedImage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleFeaturedImage({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: { 
      featuredImage,
      headerVideo = {},
    },
  } = usePageContext();

  const [videoLoaded, setVideoLoaded] = useState(false);
  const mediaItemUrl = headerVideo?.video?.mediaItemUrl || null;

  featuredImage = !!(featuredImage && featuredImage.node) && {
    ...featuredImage.node,
    width: "1368",
    height: "912",
  };

  if (!featuredImage) {
    return null;
  }

  return (
    <div className={clsx(styles.component, className)} style={{ position: "relative" }}>
      <Image
        className={clsx(styles.image, className, { [styles.hidden]: videoLoaded })}
        {...featuredImage}
        {...restProps}
      />

      {mediaItemUrl && (
        <video
          className={clsx(styles.video)}
          src={mediaItemUrl}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => setVideoLoaded(true)}
        />
      )}
    </div>
  );
}
