/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { SkipTo, Link, Icon } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header.module.css";
import HeaderFlyOutMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderFlyOutMenu";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import LanguageSwitcher from "./LanguageSwitcher";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

const homeURLs = {
  sv: "/",
  en: "/en/",
  de: "/de/",
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t, i18n } = useTranslation();
  const pageContext = usePageContext();
  let translations = pageContext?.contentNode?.translations || [];
  let currentLanguage = i18n.language;

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(layout.component, layout.componentWidthFull)}>
        <SkipTo />
        <div className={styles.inner}>
          <HeaderLogo linkTo={homeURLs[currentLanguage] || "/"} />
          <HeaderMainMenu
            menuName={"horizontalMenu"}
            aria-label={t("mainMenu")}
            className={styles.mainMenuDesktop}
          />
          <div className={clsx(styles.actions)}>
            <Link
              to={currentLanguage === "sv" ? "/sok" : "/en/search"}
              className={clsx(styles.search)}
              aria-label={t("siteSearchTitle")}
              css={css`
                display: inline-flex;
                gap: 0.25rem;
                align-items: center;
                --link-text-decoration: none;
                --link-hover-text-decoration: underline;
              `}
            >
              <Icon
                name="search"
                css={css`
                  width: 1.15rem;
                  height: 1.15rem;
                `}
              />
              <span
                css={css`
                  font-size: 0.875rem;
                  line-height: 1;
                `}
              >
                {t("siteSearchTitle")}
              </span>
            </Link>
            <LanguageSwitcher
              translations={translations}
              currentLanguage={currentLanguage}
            />
            <HeaderFlyOutMenu />
          </div>
        </div>
        <HeaderMainMenu
          menuName={"horizontalMenu"}
          aria-label={t("mainMenu")}
          className={styles.mainMenuMobile}
        />
      </div>
    </header>
  );
}
