import { useSearch } from "@whitespace/gatsby-plugin-search/src/hooks";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import SearchHits from "./SearchHits";

SearchResults.propTypes = {
  gridView: PropTypes.bool,
};

export default function SearchResults({ gridView, ...restProps }) {
  const { t } = useTranslation();
  const { hits, isPending, error, isEmptySearch } = useSearch();
  
  const sortedHits = hits
    ? [...hits].sort((a, b) => new Date(a.sortDate) - new Date(b.sortDate))
    : [];

  return (
    <div {...restProps}>
      {isEmptySearch ? (
        <div>{""}</div>
      ) : isPending ? (
        <div>{t("Fetching search results…")}</div>
      ) : error ? (
        <div>{t("An error occurred while fetching search results")}</div>
      ) : !hits || hits.length === 0 ? (
        <div>{t("Found no search results")}</div>
      ) : (
        <SearchHits hits={sortedHits} gridView={gridView} />
      )}
    </div>
  );
}
