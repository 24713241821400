/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

import * as styles from "./LanguageSwitcher.module.css";

const homeURLs = {
  sv: "/",
  en: "/en/",
  de: "/de/",
};

const languages = [
  { code: "sv", label: "Svenska" },
  { code: "en", label: "English" },
  { code: "de", label: "Deutsch" },
];

export default function LanguageSwitcher({ translations, currentLanguage }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (lang) => {
    const translationURL =
      translations.find((t) => t.language.slug === lang)?.uri || homeURLs[lang];

    window.location.href = translationURL;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className={clsx(styles.languageSwitcher)}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={clsx(styles.languageButton)}
        aria-label="Switch language"
      >
        <Icon name="globe" />
        {languages.find((lang) => lang.code === currentLanguage)?.label}
      </button>
      {isOpen && (
        <ul
          className={clsx(styles.languageDropdown)}
        >
          {languages.map(({ code, label }) => (
            <li key={code}>
              <button
                onClick={() => handleLanguageChange(code)}
                className={clsx(styles.languageOption)}
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

LanguageSwitcher.propTypes = {
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      uri: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentLanguage: PropTypes.oneOf(["sv", "en", "de"]).isRequired,
};
